dmx.Component('google-autocomplete', {

    extends: 'input',

    initialData:{
        placeId: null,
        address: null,
        phone: null,
        phone2: null,
        latitude: null,
        longitude: null,
        icon: null,
        name: null,
        priceLevel: null,
        rating: null,
        types: null,
        url: null,
        utcOffset: null, // in minutes
        vicinity: null,
        website: null
    },

    attributes: {
        map: {
            type: String,
            default: null
        },

        country: { // restrict to country (ISO 3166-1 Alpha-2 country code) (up to 5 max, separate with comma)
            type: String,
            default: null
        },

        types: {
            type: String,
            default: null // geocode, address, establishment, (regions), (cities)
        },

        'strict-bounds': {
            type: Boolean,
            default: false
        },

        'move-map': {
            type: Boolean,
            default: false
        }
    },

    render: function(node) {
        dmx.BaseComponent.prototype.render.call(this, node);
        this.$node.value = this.props.value;
        this.$node.disabled = this.props.disabled;
        this.$node.defaultValue = this.props.value;
        this.set('value', this.props.value);
        this.set('disabled', this.props.disabled);

        this.target = document.getElementById(this.props.map);
        this.map = this.target && this.target.dmxComponent && this.target.dmxComponent.map;

        this.autocomplete = new google.maps.places.Autocomplete(this.$node, {
            strictBounds: this.props['strict-bounds'],
            types: this.props.types ? this.props.types.split(/\s*,\s*/) : [],
        });

        if (this.map) {
            this.autocomplete.bindTo('bounds', this.map);
        }

        if (this.props.country) {
            this.autocomplete.setComponentRestrictions({country: this.props.country.split(/\s*,\s*/)});
        }

        this.autocomplete.addListener('place_changed', this.onchange.bind(this));
    },

    update: function(props) {
        if (this.props.map && !this.map) {
            this.map = this.target && this.target.dmxComponent && this.target.dmxComponent.map;
            this.autocomplete.bindTo('bounds', this.map);
        }

        if (props['strict-bounds'] != this.props['strict-bounds']) {
            this.autocomplete.setOptions({ strictBounds: this.props['strict-bounds'] });
        }

        if (props.types != this.props.types) {
            this.autocomplete.setOptions({ types: this.props.types });
        }

        if (props.country != this.props.country) {
            this.autocomplete.setComponentRestrictions({country: this.props.country ? this.props.country.split(/\s*,\s*/) : []});
        }

        if (props.disabled != this.props.disabled) {
            this.$node.disabled = this.props.disabled;
        }
    },

    onchange: function() {
        var place = this.autocomplete.getPlace();
        if (!place.place_id) return;

        this.set('value', this.$node.value);
        this.set({
            placeId: place.place_id,
            address: place.formatted_address,
            phone: place.formatted_phone_number,
            phone2: place.international_phone_number,
            latitude: place.geometry && place.geometry.location.lat(),
            longitude: place.geometry && place.geometry.location.lng(),
            attributions: place.html_attributions,
            icon: place.icon,
            name: place.name,
            isOpen: place.opening_hours && place.opening_hours.isOpen(),
            priceLevel: place.price_level,
            rating: place.rating,
            types: place.types,
            url: place.url,
            userRatingsTotal: place.user_ratings_total,
            utcOffset: place.utc_offset_minutes,
            vicinity: place.vicinity,
            website: place.website,
            adrAddress: place.adr_address,
            components: Array.isArray(place.address_components) ? place.address_components.reduce(function(output, component) {
                component.types.forEach(function(type) {
                    output[type.replace(/_(\w)/g, function(m, c) { return c.toUpperCase() })] = {
                        long: component.long_name,
                        short: component.short_name
                    }
                });

                return output;
            }, {}) : null
        });

        if (this.props['move-map'] && this.map) {
            if (place.geometry.viewport) {
                this.map.fitBounds(place.geometry.viewport);
            } else {
                this.map.setCenter(place.geometry.location);
                this.map.setZoom(17);
            }
        }

        setTimeout(this.dispatchEvent.bind(this, 'updated'), 100);
    }

});
